import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import DialogBox from "./DialogBox";
import { backendApiUrl } from "../utils";

const MassActions = (props) => {
  const [state, setState] = useState({
    confirmBox: false,
    msg: {},
    from: "",
    approved: false,
  });

  const [emaillistType, setEmaillistType] = useState("");

  const statusClickHandler = (status) => {
    let st = { ...state };
    st.approved = status;
    st.confirmBox = false;
    setState(st);
  };

  const onClickHandler = (from) => {
    let st = { ...state };
    st.from = from;
    st.confirmBox = true;

    if (from === "Delete Selected") {
      st.msg = "Are you sure you want to deactivate the selected items?";
    } else if (from === "Delete All") {
      st.msg = "Are you sure want to deactivate all items?";
    } else if (from === "Share Selected Loans") {
      st.msg = "Are you sure want to share the selected loans?";
    } else if (from === "Share All Loans") {
      st.msg = "Are you sure want to share the all loans?";
    } else {
      st.confirmBox = false;
      let info = { ...props.info };
      info.openCompose = true;
      if (props.module === "Leads") {
        info.url = backendApiUrl("leads/mass_action");
      } else {
        info.url = backendApiUrl("module/mass_action");
      }
      info.from = from;
      props.actions.setActionInfo(info);
    }
    console.log(st);
    setState(st);
  };

  useEffect(() => {
    if (state.approved && !!state.from) {
      let st = { ...state };

      let data = {
        from: st.from,
        module: props.module,
        Ids: props.info.Ids,
      };
      if (st.from === "Share All Loans" || st.from === "Share Selected Loans") {
        data.affiliate_company = props.affiliateCompany;
      }
      if (props.filters) data.filters = props.filters;
      axios
        .post(props.url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          let tmsg = "";
          if (st.from === "Delete Selected") {
            tmsg = "Selected items deleted successfully.";
          } else if (st.from === "Delete All") {
            tmsg = "All items deleted successfully.";
          }

          props.actions.setMsg({
            status: 200,
            msg: tmsg,
            show: true,
          });

          props.actions.setLoading(true);
          props.actions.setActionInfo({});

          st.confirmBox = false;
          st.from = "";
          setState(st);
        })
        .catch((err) => {
          st.confirmBox = false;
          st.from = "";
          setState(st);
          props.actions.setMsg({
            status: 500,
            msg: "Unable to process the request.",
            show: true,
          });
        });
    }
  }, [
    props.actions,
    props.filters,
    props.info.Ids,
    props.module,
    props.title,
    props.url,
    props.affiliateCompany,
    state,
  ]);

  return (
    <span>
      {state.confirmBox && (
        <DialogBox msg={state.msg} setStatus={statusClickHandler} />
      )}
      <ButtonToolbar aria-label="Toolbar with button groups">
        <ButtonGroup className="mr-2">
          <Button size="sm" onClick={() => onClickHandler("Delete Selected")}>
            Delete Selected
          </Button>
        </ButtonGroup>
        <ButtonGroup className="mr-2">
          <Button size="sm" onClick={() => onClickHandler("Delete All")}>
            Delete All
          </Button>
        </ButtonGroup>
        <ButtonGroup className="mr-2">
          <Button
            size="sm"
            onClick={() => onClickHandler("Send Mail To Selected")}
          >
            Send Mail To Selected
          </Button>
        </ButtonGroup>
        <ButtonGroup className="mr-2">
          <Button size="sm" onClick={() => onClickHandler("Send Mail To All")}>
            Send Mail To All
          </Button>
        </ButtonGroup>

        {["Contacts"].includes(props?.form?.title) && (
          <>
            <ButtonGroup>
              <Button
                size="sm"
                className="ml-2"
                onClick={() => setEmaillistType("Create List (Selected)")}
              >
                Create / Update List (Selected)
              </Button>
              <Button
                size="sm"
                className="ml-2"
                onClick={() => setEmaillistType("Create List (All)")}
              >
                Create / Update List (All)
              </Button>
            </ButtonGroup>
          </>
        )}
        {props.affiliateCompany && (
          <>
            <ButtonGroup className="mr-2">
              <Button
                size="sm"
                onClick={() => onClickHandler("Share Selected Loans")}
              >
                Share Selected Loans
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button
                size="sm"
                onClick={() => onClickHandler("Share All Loans")}
              >
                Share All Loans
              </Button>
            </ButtonGroup>
          </>
        )}
      </ButtonToolbar>
      {!!emaillistType && (
        <CreateEmailListModal
          from={emaillistType}
          selectedIds={props.info.Ids}
          filters={props.filters}
          actions={props.actions}
          handleClose={setEmaillistType}
        />
      )}
    </span>
  );
};

const CreateEmailListModal = ({
  from,
  selectedIds,
  filters,
  actions,
  handleClose,
}) => {
  const [title, setTitle] = useState("");

  const [errors, setErrors] = useState([]);

  const [load, setLoad] = useState(true);

  const [emailList, setEmailList] = useState([]);

  const [emailListId, setEmailListId] = useState("");

  const [create, setCreate] = useState(true);

  const handleCreateEmailList = () => {
    setEmailListId("");
    setCreate(true);
  };

  useEffect(() => {
    if (load) {
      axios
        .post(
          backendApiUrl("email_list/lists"),
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setEmailList(res.data);
          setLoad(false);
        })
        .catch((err) => { });
    }
  }, [load]);

  const onSubmit = async () => {
    if (create) {
      if (title === "") {
        setErrors(["title"]);
        return true;
      }
    } else {
      if (emailListId === "") {
        setErrors(["emailListId"]);
        return true;
      }
    }

    await axios
      .post(
        backendApiUrl("contacts/create_email_list"),
        {
          filters,
          selectedIds,
          emailListId,
          title,
          type: from === "Create List (All)" ? "All" : "Selected",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        handleClose("");
        actions.setMsg({
          status: 200,
          msg: res.data.message,
          show: true,
        });
      })
      .catch((err) => {
        actions.setMsg({
          status: 500,
          msg: "Something went wrong, could not complete the operation",
          show: true,
        });
      });
  };

  return (
    <Modal show={true} onHide={() => handleClose("")}>
      {load ? (
        <div style={{ textAlign: "center" }} className="m-2">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              Confirm Create / Update List{" "}
              {from === "Create List (Selected)" ? "(Selected)" : "(All)"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Do you want to create a new list, or update an existing one?
            </p>
            <Form.Check
              type="radio"
              id="create"
              label="Create a New List"
              checked={create}
              onChange={handleCreateEmailList}
            />
            <Form.Check
              type="radio"
              id="updateExistingList"
              label="Update an Existing List"
              checked={!create}
              onChange={() => setCreate(false)}
            />
            {create ? (
              <Form.Group controlId="title">
                <Form.Label>
                  List Name: <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter List Name"
                  className={
                    "form-control" +
                    (errors.includes("title") ? " is-invalid" : "")
                  }
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {errors.includes("title") && (
                  <div className="invalid-feedback">
                    Please provide a valid Title
                  </div>
                )}
              </Form.Group>
            ) : (
              <Form.Group controlId="emailListId">
                <Form.Label>
                  Lists: <span className="text-danger">*</span>
                </Form.Label>
                <select
                  value={emailListId}
                  className={
                    "form-control" +
                    (errors.includes("emailListId") ? " is-invalid" : "")
                  }
                  onChange={(e) => setEmailListId(e.target.value)}
                >
                  <option value="">Select</option>
                  {emailList.map((list) => (
                    <option value={list._id} key={list._id}>
                      {list.title}
                    </option>
                  ))}
                </select>
                {errors.includes("emailListId") && (
                  <div className="invalid-feedback">
                    Please select List
                  </div>
                )}
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose("")}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onSubmit}>
              Yes, {create ? "Create" : "Update"} List
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default MassActions;
