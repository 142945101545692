import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { metronic } from "../../../_metronic";
import Message from "../../utils/Message";
import { getObjectVal } from "../../utils";

const SubHeader = (props) => {
  const currentUrl = window.location.pathname;
  const dispatch = useDispatch();

  const {
    moduleBuilderConfig,
    opportunityBuilderConfig,
    webformBuilderConfig,
    libraryBuilderConfig,
    menuConfig,
  } = useSelector(
    ({ builder }) => ({
      moduleBuilderConfig: builder.moduleBuilderConfig,
      opportunityBuilderConfig: builder.opportunityBuilderConfig,
      webformBuilderConfig: builder.webformBuilderConfig,
      libraryBuilderConfig: builder.libraryBuilderConfig,
      menuConfig: builder.menuConfig,
    }),
    shallowEqual
  );

  const setMsg = () => {
    switch (currentUrl) {
      case "/builders/modules/form":
        let sConfig = { ...moduleBuilderConfig };
        sConfig.msg = {};
        dispatch(metronic.builder.actions.setModuleBuilder(sConfig));
        break;
      case "/builders/opportunity/form":
        let pConfig = { ...opportunityBuilderConfig };
        pConfig.msg = {};
        dispatch(metronic.builder.actions.setOpportunityBuilder(pConfig));
        break;
      case "/builders/webforms/form":
        let wConfig = { ...webformBuilderConfig };
        wConfig.msg = {};
        dispatch(metronic.builder.actions.setWebformBuilder(wConfig));
        break;
      case "/builders/libraries/form":
        let config = { ...libraryBuilderConfig };
        config.msg = {};
        dispatch(metronic.builder.actions.setLibraryBuilder(config));
        break;
      default:
        break;
    }
  };

  if (
    currentUrl.includes("/forms/") ||
    currentUrl.includes("/public/") ||
    currentUrl.includes("/leads/")
  ) {
    return "";
  }

  return (
    <div
      id="kt_subheader"
      className="kt-subheader  kt-grid__item"
      style={{
        marginTop:
          getObjectVal(menuConfig.personalization, "layout", "Horizontal") ===
            "Vertical"
            ? "0px"
            : "43px",
      }}
    >
      <div className={`kt-container kt-container--fluid`}>
        <div className="kt-subheader__main">
          <span className="kt-subheader__separator kt-subheader__separator--v" />
          <h3
            className="kt-subheader__title"
            style={
              menuConfig &&
                menuConfig.personalization &&
                menuConfig.personalization.mode === "Dark"
                ? { color: "#fff" }
                : {}
            }
          >
            {menuConfig.header.items.map((m, i) => (
              <span key={i}>
                {m.show_menu === true ? (
                  m.createdAt !== undefined && currentUrl === "/" + m.page ? (
                    <>
                      {currentUrl.includes("/" + m.page + "/") ? (
                        <Link to={"/" + m.page}>
                          {!!m.custom_title ? m.custom_title : m.title}
                        </Link>
                      ) : (
                        m.custom_title || m.title
                      )}
                    </>
                  ) : m.createdAt !== undefined &&
                    currentUrl === "/" + m.page + "/create" ? (
                    <span>
                      <Link to={"/" + m.page}>
                        {!!m.custom_title ? m.custom_title : m.title + " "}
                      </Link>
                      &nbsp;&gt;&gt;&nbsp; Create
                    </span>
                  ) : m.createdAt !== undefined &&
                    currentUrl.includes("/" + m.page + "/edit") ? (
                    <span>
                      <Link to={"/" + m.page}>
                        {!!m.custom_title ? m.custom_title : m.title + " "}
                      </Link>
                      &nbsp;&gt;&gt;&nbsp; Edit
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </span>
            ))}

            {currentUrl === "/loans" && "Loans"}
            {currentUrl.includes("/loans/edit/") && (
              <span>
                {props.user.type !== "Prospects" && (
                  <>
                    <Link to={"/prospects"}>Prospects </Link>
                    &nbsp;&gt;&gt;&nbsp;
                  </>
                )}
                <Link to={"/loans"}>Loans</Link>
                &nbsp;&gt;&gt;&nbsp;
                <Link to={"/prospects/edit/" + moduleBuilderConfig.prospectId}>
                  Prospect Profile
                </Link>
                &nbsp;&gt;&gt;&nbsp;Update
              </span>
            )}

            {currentUrl === "/companies" && "Companies"}
            {currentUrl === "/companies/new" && (
              <>
                {props.user.type === "Super" ? (
                  <Link to="/companies"> Companies </Link>
                ) : (
                  "Companies"
                )}
                &nbsp; &gt;&gt; Create
              </>
            )}
            {currentUrl.includes("/companies/") &&
              currentUrl !== "/companies/new" && (
                <>
                  {props.user.type === "Super" ? (
                    <>
                      <Link to="/companies"> Companies </Link>
                      &nbsp; &gt;&gt; Profile
                    </>
                  ) : (
                    "Company Profile"
                  )}
                </>
              )}

            {currentUrl === "/groups" && "Groups"}

            {currentUrl === "/groups/create" && (
              <>
                <Link to="/groups"> Groups </Link>
                &nbsp; &gt;&gt; Create
              </>
            )}
            {currentUrl.includes("/groups/") &&
              currentUrl !== "/groups/create" && (
                <>
                  <Link to="/groups"> Groups </Link>
                  &nbsp; &gt;&gt; Update
                </>
              )}

            {currentUrl === "/builders/modules" && "Builders >> Modules"}
            {currentUrl === "/builders/modules/create" && (
              <>
                <Link to="/builders/modules">
                  Builders&nbsp;&gt;&gt;&nbsp;Modules
                </Link>
                &nbsp;&gt;&gt; Create
              </>
            )}
            {currentUrl.includes("/builders/modules/edit/") && (
              <>
                <Link to="/builders/modules">
                  Builders&nbsp;&gt;&gt;&nbsp;Modules
                </Link>
                &nbsp;&gt;&gt; Edit
              </>
            )}
            {currentUrl === "/builders/modules/form" && (
              <>
                <Link to="/builders/modules">
                  Builders&nbsp;&gt;&gt;&nbsp;Modules
                </Link>
                &nbsp;&gt;&gt;&nbsp;
                {!!moduleBuilderConfig.custom_title
                  ? moduleBuilderConfig.custom_title
                  : moduleBuilderConfig.title}
              </>
            )}

            {currentUrl === "/builders/opportunity" && "Builders >> Loans"}
            {currentUrl === "/builders/opportunity/create" && (
              <>
                <Link to="/builders/opportunity">
                  Builders&nbsp;&gt;&gt;&nbsp;Loans
                </Link>
                &nbsp;&gt;&gt; Create
              </>
            )}
            {currentUrl.includes("/builders/opportunity/edit/") && (
              <>
                <Link to="/builders/opportunity">
                  Builders&nbsp;&gt;&gt;&nbsp;Loans
                </Link>
                &nbsp;&gt;&gt; Edit
              </>
            )}
            {currentUrl === "/builders/opportunity/form" && (
              <>
                <Link to="/builders/opportunity">
                  Builders&nbsp;&gt;&gt;&nbsp;Loans
                </Link>
                &nbsp;&gt;&gt; Form
              </>
            )}

            {currentUrl === "/builders/webforms" && "Builders >> Webforms"}
            {currentUrl === "/builders/webforms/create" && (
              <>
                <Link to="/builders/webforms">
                  Builders&nbsp;&gt;&gt;&nbsp;Webforms
                </Link>
                &nbsp;&gt;&gt; Create
              </>
            )}
            {currentUrl.includes("/builders/webforms/edit/") && (
              <>
                <Link to="/builders/webforms">
                  Builders&nbsp;&gt;&gt;&nbsp;Webforms
                </Link>
                &nbsp;&gt;&gt; Edit
              </>
            )}
            {currentUrl === "/builders/webforms/form" && (
              <>
                <Link to="/builders/webforms">
                  Builders&nbsp;&gt;&gt;&nbsp;Webforms
                </Link>
                &nbsp;&gt;&gt;&nbsp;
                {!!webformBuilderConfig.custom_title
                  ? webformBuilderConfig.custom_title
                  : webformBuilderConfig.title}
              </>
            )}

            {currentUrl === "/builders/libraries" && "Builders >> Libraries"}
            {currentUrl === "/builders/libraries/create" && (
              <>
                <Link to="/builders/libraries">
                  Builders&nbsp;&gt;&gt;&nbsp;Libraries
                </Link>
                &nbsp;&gt;&gt; Create
              </>
            )}
            {currentUrl.includes("/builders/libraries/edit/") && (
              <>
                <Link to="/builders/libraries">
                  Builders&nbsp;&gt;&gt;&nbsp;Libraries
                </Link>
                &nbsp;&gt;&gt; Edit
              </>
            )}
            {currentUrl === "/builders/libraries/form" && (
              <>
                <Link to="/builders/libraries">
                  Builders&nbsp;&gt;&gt;&nbsp;Libraries
                </Link>
                &nbsp;&gt;&gt;&nbsp;
                {!!libraryBuilderConfig.custom_title
                  ? libraryBuilderConfig.custom_title
                  : libraryBuilderConfig.title}
              </>
            )}

            {currentUrl === "/email_templates" && "Builders >> Email Templates"}
            {currentUrl === "/email_templates/create" && (
              <>
                <Link to="/email_templates">
                  Builders&nbsp;&gt;&gt;&nbsp;Email Templates
                </Link>
                &nbsp;&gt;&gt;&nbsp;Create
              </>
            )}
            {currentUrl.includes("/email_templates/edit/") && (
              <>
                <Link to="/email_templates">
                  Builders&nbsp;&gt;&gt;&nbsp;Email Templates
                </Link>
                &nbsp;&gt;&gt;&nbsp;Edit
              </>
            )}
            {currentUrl.includes("/email_templates/builder/") && (
              <>
                <Link to="/email_templates">
                  Builders&nbsp;&gt;&gt;&nbsp;Email Templates
                </Link>
                &nbsp;&gt;&gt;&nbsp;Builder
              </>
            )}

            {currentUrl === "/builders/email_triggers" &&
              "Builders >> Email Triggers"}
            {currentUrl === "/builders/email_triggers/create" && (
              <>
                <Link to="/builders/email_triggers">
                  Builders&nbsp;&gt;&gt;&nbsp;Email Triggers
                </Link>
                &nbsp;&gt;&gt;&nbsp;Create
              </>
            )}
            {currentUrl.includes("/builders/email_triggers/edit/") && (
              <>
                <Link to="/builders/email_triggers">
                  Builders&nbsp;&gt;&gt;&nbsp;Email Triggers
                </Link>
                &nbsp;&gt;&gt;&nbsp;Edit
              </>
            )}
            {currentUrl.includes("/builders/email_triggers/builder/") && (
              <>
                <Link to="/builders/email_triggers">
                  Builders&nbsp;&gt;&gt;&nbsp;Email Triggers
                </Link>
                &nbsp;&gt;&gt;&nbsp;Builder
              </>
            )}

            {currentUrl === "/builders/contracts" &&
              "Builders >> Contract Templates"}
            {currentUrl === "/builders/contracts/create" && (
              <>
                <Link to="/builders/contracts">
                  Builders&nbsp;&gt;&gt;&nbsp;Contract Templates
                </Link>
                &nbsp;&gt;&gt;&nbsp;Create
              </>
            )}
            {currentUrl.includes("/builders/contracts/edit/") && (
              <>
                <Link to="/builders/contracts">
                  Builders&nbsp;&gt;&gt;&nbsp;Contract Templates
                </Link>
                &nbsp;&gt;&gt;&nbsp;Edit
              </>
            )}
            {currentUrl.includes("/builders/contracts/builder/") && (
              <>
                <Link to="/builders/contracts">
                  Builders&nbsp;&gt;&gt;&nbsp;Contract Templates
                </Link>
                &nbsp;&gt;&gt;&nbsp;Builder
              </>
            )}

            {currentUrl === "/products" && "Products"}
            {currentUrl === "/products/create" && (
              <>
                <Link to="/products">Products</Link>
                &nbsp;&gt;&gt;&nbsp;Create
              </>
            )}
            {currentUrl.includes("/products/") &&
              currentUrl !== "/products/create" && (
                <>
                  <Link to="/products">Products</Link>
                  &nbsp;&gt;&gt;&nbsp;Edit
                </>
              )}

            {currentUrl === "/reports" && "Reports"}
            {currentUrl === "/reports/create" && (
              <>
                <Link to="/reports">Reports</Link>
                &nbsp;&gt;&gt;&nbsp;Create
              </>
            )}
            {currentUrl.includes("/reports/") &&
              currentUrl !== "/reports/create" && (
                <>
                  <Link to="/reports">Reports</Link>
                  &nbsp;&gt;&gt;&nbsp;Edit
                </>
              )}

            {currentUrl === "/leads" && "Leads"}
            {currentUrl === "/imports" && "Data Imports"}
            {currentUrl === "/imports/create" && (
              <>
                <Link to="/imports">Data Imports</Link>
                &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;Create
              </>
            )}
            {currentUrl.includes("/imports/edit") && (
              <>
                <Link to="/imports">Data Imports</Link>
                &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;Field Mapping
              </>
            )}

            {currentUrl === "/dashboard" && "Dashboard"}

            {currentUrl === "/campaigns" && "Campaigns"}
            {currentUrl.includes("/campaigns/create") && (
              <>
                <Link to="/campaigns">Campaigns</Link>
                &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;Create a New Campaign
              </>
            )}
            {currentUrl.includes("/campaigns/emails/") && (
              <>
                <Link to="/campaigns">Campaigns</Link>
                &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;Emails
              </>
            )}


            {currentUrl === "/whatsapp_campaigns" && "WhatsApp Campaigns"}
            {currentUrl.includes("/whatsapp_campaigns/create") && (
              <>
                <Link to="/whatsapp_campaigns">WhatsApp Campaigns</Link>
                &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;Create a New Campaign
              </>
            )}
            {currentUrl.includes("/whatsapp_campaigns/messages/") && (
              <>
                <Link to="/whatsapp_campaigns">WhatsApp Campaigns</Link>
                &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;Messages
              </>
            )}

            {currentUrl === "/security/roles" && "Security >> Roles"}
            {(currentUrl === "/security/roles/workforce/create" ||
              currentUrl === "/security/roles/contact/create") && (
                <>
                  <Link to="/security/roles">Security &gt;&gt;&nbsp;Roles</Link>
                  &nbsp;&gt;&gt;&nbsp;Create
                </>
              )}
            {(currentUrl.includes("/security/roles/workforce/edit/") ||
              currentUrl.includes("/security/roles/contact/edit/")) && (
                <>
                  <Link to="/security/roles">Security&gt;&gt;&nbsp;Roles</Link>
                  &nbsp; &gt;&gt;&nbsp;Edit
                </>
              )}

            {currentUrl.includes("/standard_docs/map/") &&
              "Standard Docs >> Field Mapping"}

            {currentUrl === "/builders/tasks" && "Builders >> Tasks"}
            {currentUrl === "/builders/task/create" && (
              <>
                <Link to="/builders/tasks">
                  Builders&nbsp;&gt;&gt;&nbsp;Task
                </Link>
                &nbsp;&gt;&gt;&nbsp;Create
              </>
            )}
            {currentUrl.includes("/builders/task/edit/") && (
              <>
                <Link to="/builders/tasks">
                  Builders&nbsp;&gt;&gt;&nbsp;Task
                </Link>
                &nbsp;&gt;&gt;&nbsp;Edit
              </>
            )}
            {currentUrl.includes("/builders/task/builder/") && (
              <>
                <Link to="/builders/task/builder">
                  Builders&nbsp;&gt;&gt;&nbsp;Task
                </Link>
                &nbsp;&gt;&gt;&nbsp;Builder
              </>
            )}
            {currentUrl === "/email_list" && "List"}
            {currentUrl === "/email_list/create" && (
              <>
                <Link to="/email_list">List</Link>
                &nbsp;&gt;&gt;&nbsp;Create
              </>
            )}
            {currentUrl.includes("/email_list/edit") && (
              <>
                <Link to="/email_list">List</Link>
                &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;Edit
              </>
            )}
          </h3>
        </div>
        <div className="col-md-6" id="res_message" align="right">
          {currentUrl === "/builders/modules/form" &&
            moduleBuilderConfig.msg &&
            moduleBuilderConfig.msg.show && (
              <Message
                status={moduleBuilderConfig.msg.status}
                setMsg={setMsg}
                msg={moduleBuilderConfig.msg.msg}
              />
            )}
          {currentUrl === "/builders/opportunity/form" &&
            opportunityBuilderConfig.msg &&
            opportunityBuilderConfig.msg.show && (
              <Message
                status={opportunityBuilderConfig.msg.status}
                setMsg={setMsg}
                msg={opportunityBuilderConfig.msg.msg}
              />
            )}
          {currentUrl === "/builders/webforms/form" &&
            webformBuilderConfig.msg &&
            webformBuilderConfig.msg.show && (
              <Message
                status={webformBuilderConfig.msg.status}
                setMsg={setMsg}
                msg={webformBuilderConfig.msg.msg}
              />
            )}
          {currentUrl === "/builders/libraries/form" &&
            libraryBuilderConfig.msg &&
            libraryBuilderConfig.msg.show && (
              <Message
                status={libraryBuilderConfig.msg.status}
                setMsg={setMsg}
                msg={libraryBuilderConfig.msg.msg}
              />
            )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(SubHeader);
